<template>
  <div class="wrapper-page">
    <div class="ex-page-content text-center">
      <div class="text-error">
        <span class="text-primary">4</span>
        <i class="ti-face-sad text-pink"></i>
        <span class="text-info">4</span>
      </div>
      <h2>Whops! Page not found</h2><br>
      <p class="text-muted">
        This page cannot found or is missing.
      </p>
      <p class="text-muted">
        Use the navigation above or the button below to get back and track.
      </p>
      <br>
      <a class="btn btn-default waves-effect waves-light" @click.prevent="onReturnDashboard">
        Regresar al HOME</a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Router from '@/router'

export default {
  data() {
    return { error: '' }
  },
  methods: {
    onReturnDashboard() {
      const _this = this
      if (+_this.setting.roleId === 4) {
        Router.push({ name: 'HomeSellers' })
      } else if (+_this.setting.roleId === 3) {
        Router.push({ name: 'HomeSupport' })
      } else {
        Router.push({ name: 'Home' })
      }
    },
  },
  computed: {
    ...mapGetters(['setting']),
  },
}
</script>
